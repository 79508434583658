<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="id" _key="btnBankAccountCreate" title="Conta Bancária" classIcon="fas fa-plus-circle"
          type="primary" size="small" :clicked="create" />
      </div>
      <div slot="content-main">
        <BankAccountCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import BankAccountCreateUpdate from "../../../components/modules/finance/bank-account/BankAccountCreateUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "BankAccountCreateUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    BankAccountCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Financeiro",
        title: "Conta Bancária",
        formName: "productCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "bankAccountCreate",
      });
      this.removeLoading(["btnBankAccountCreate"]);
    },
  },
};
</script>
