export default class BankAccountCreateUpdate {
    constructor() {
        this.id = "";
        this.name = "";
        this.urlLogo = "";
        this.bank = { id: "", content: "" };
        this.agency = "";
        this.agencyDigit = "";
        this.type = 1;
        this.accountNumber = "";
        this.accountDigit = "";
        this.openingBalance = 0;
        this.accountBalance = "";
        this.pluggyId = "";
    }
    update(data) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
        this.urlLogo = data.urlLogo;

        if (this.type == 1) {
            this.bank = { id: data.bank.id, content: data.bank.name };
            this.agency = data.agency;
            this.agencyDigit = data.agencyDigit;
            this.accountNumber = data.accountNumber;
            this.accountDigit = data.accountDigit;
        }

        this.openingBalance = data.openingBalance;
        this.accountBalance = data.accountBalance;
        this.pluggyId = data.pluggyId;
    }
}