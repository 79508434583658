<template>
    <div>
        <div v-if="pluggyId">
            <Alert type="info">
                <span>
                    Sua conta <b>ESTÁ</b> conectada ao Open Finance!
                </span>
                <Button _key="btnDisconnect" title="Desconectar" backGroundColor="#EF294B" color="white" size="small"
                    :clicked="disconnectAccount" />
            </Alert>
        </div>

        <div v-else>
            <Alert type="info">
                <span>
                    Sua conta <b>NÃO</b> está conectada ao Open Finance!
                </span>
                <Button _key="btnConnect" title="Conectar" backGroundColor="#0A36DB" color="white" size="small"
                    :clicked="getConnectToken" />
            </Alert>
        </div>
    </div>
</template>


<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapActions, mapMutations } from "vuex";

export default {
    name: 'PluggyConnect',
    props: ["pluggyId"],
    components: {
        Button, Alert
    },
    data() {
        return {
            id: this.$route.params.id,
            urlGetConnectToken: "/api/v1/finance/bank-account/get-connect-token",
            urlUpdate: "/api/v1/finance/bank-account/update-pluggyId",
            urlDisconnect: "/api/v1/finance/bank-account/disconnect-account",
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["getApi", "putApi"]),
        getConnectToken() {
            let params = {
                url: this.urlGetConnectToken,
            };
            this.getApi(params).then((response) => {
                this.openPluggyConnect(response.content);
            });
        },
        openPluggyConnect(connectToken) {
            this.removeLoading(["btnConnect"]);
            const pluggyConnect = new window.PluggyConnect({
                connectToken: connectToken,
                includeSandbox: false,
                onSuccess: (itemData) => {
                    this.updateBankAccount(itemData.item.id);
                },
                onError: (error) => {
                    console.error('Error:', error);
                },
            });
            pluggyConnect.init();
        },
        updateBankAccount(itemId) {
            let params = { url: this.urlUpdate, obj: { id: this.id, any: itemId } };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "pluggyUpdated" });
                }
            });
        },
        disconnectAccount() {
            let params = { url: this.urlDisconnect, obj: { id: this.id } };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "pluggyUpdated" });
                }
            });
        },
    },
};
</script>
<style scoped></style>