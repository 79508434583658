<template>
  <div class="margin">
    <Molded v-if="id">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="8" xl="8">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="1" xl="1">
              <img :src="'https://espaco.blob.core.windows.net/nixloc-bank-logo/' +
      bankAccount.urlLogo
      " class="img img-bank" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="11" xl="11">
              <div class="div-balance" :class="{
      positive: bankAccount.accountBalance >= 0,
      negative: bankAccount.accountBalance < 0,
    }">
                Saldo Atual {{ bankAccount.accountBalance | currency }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </Molded>
    <br v-if="id" />
    <Molded>
      <div class="div-pluggy" v-if="id && numberOfOpenFinance > 0">
        <PluggyConnect :pluggyId="bankAccount.pluggyId" />
      </div>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <RadioGroup :formName="formName" :options="[
      { text: 'Conta Bancária', value: 1 },
      { text: 'Caixa Interno', value: 2 },
    ]" v-model="bankAccount.type" />
        </b-col>
      </b-row>
      <b-row v-if="isBankAccount">
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <Select title="Banco" field="bank" :formName="formName" :required="isBankAccount"
            url="/api/v1/finance/bank/select-all" v-model="bankAccount.bank" nameNewRegister="bankCreateUpdate"
            titleNewRegister="Banco" :widthNewRegister="500" :heightNewRegister="250">
            <BankCreateUpdate :registerInSelect="true" v-model="bankAccount.bank" />
          </Select>
        </b-col>
      </b-row>
      <b-row v-if="isBankAccount">
        <b-col xs="6" sm="6" md="6" lg="2" xl="2">
          <InputText title="Agência" field="agency" :formName="formName" :required="isBankAccount" :maxLength="10"
            v-model="bankAccount.agency" />
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="1" xl="1">
          <InputText title="Digito" field="agencyDigit" :formName="formName" :required="false" :maxLength="10"
            v-model="bankAccount.agencyDigit" />
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="3" xl="3">
          <InputText title="Conta" field="accountNumber" :formName="formName" :required="isBankAccount" :maxLength="20"
            v-model="bankAccount.accountNumber" />
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="1" xl="1">
          <InputText title="Digito" field="accountDigit" :formName="formName" :required="false" :maxLength="10"
            v-model="bankAccount.accountDigit" />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="100"
            v-model="bankAccount.name" />
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="2" xl="2">
          <InputDecimal title="Saldo Inicial R$" field="openingBalance" :formName="formName" :required="false"
            :allowNegative="true" v-model="bankAccount.openingBalance" />
        </b-col>
      </b-row>
    </Molded>
    <br />
  </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import BankCreateUpdate from "../bank/BankCreateUpdate.vue";

import BankAccountCreateUpdate from "@/components/modules/finance/bank-account/BankAccountCreateUpdate.js";

import PluggyConnect from '../pluggy/PluggyConnect.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BankAccountCreateUpdate",
  props: {
    value: Object,
  },
  components: {
    InputText,
    InputDecimal,
    Select,
    RadioGroup,
    CheckboxGroup,
    Molded,
    Button,
    BankCreateUpdate, PluggyConnect
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "bankAccountCreateUpdate",
      bankAccount: new BankAccountCreateUpdate(),
      urlGetPlan: "/api/v1/adm/company/get-plan",
      urlCreate: "/api/v1/finance/bank-account/create",
      urlUpdate: "/api/v1/finance/bank-account/update",
      urlGetById: "/api/v1/finance/bank-account/get-by-id",
      numberOfOpenFinance: 0,
    };
  },
  created() {
    if (this.id) this.getById();
    this.getPlan();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    isBankAccount() {
      if (this.bankAccount.type == 1) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("validation", ["removeFormDirty"]),
    // o getPlan está provisório, após teste liberar para qualquer cliente e validar pela quantidade de contas bancárias contratadas
    getPlan() {
      let params = {
        url: this.urlGetPlan,
      };
      this.getApi(params).then((response) => {
        this.numberOfOpenFinance = response.content.plan.numberOfOpenFinance;
      });
    },
    create() {
      let params = { url: this.urlCreate, obj: this.bankAccount };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "bankAccountUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.bankAccount };
      this.putApi(params).then((response) => {
        if (response.success) this.getById();
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.bankAccount.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "pluggyUpdated") {
          this.getById();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-balance {
  font-size: 16px;
  margin-top: 3px;
}

.positive {
  color: darkblue;
}

.negative {
  color: red;
}

.img-bank {
  border-radius: 10px;
}

.div-pluggy {
  margin-top: 5px;
  margin-bottom: 12px;
}
</style>
