<template>
  <div>
    <b-row>
      <b-col sm="4">
        <InputText title="Número" field="number" :formName="formName" :required="true" :maxLength="10"
          v-model="bank.number" :markFormDirty="false" />
      </b-col>
      <b-col sm="8">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50" v-model="bank.name"
          :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveBank" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveBank" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Bank from "@/components/modules/finance/bank/Bank.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BankCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, Button },
  data() {
    return {
      formName: "bankCreateUpdate",
      bank: new Bank(),
      urlCreate: "/api/v1/finance/bank/create",
      urlUpdate: "/api/v1/finance/bank/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveBank() {
      if (this.bank.id) {
        let params = { url: this.urlUpdate, obj: this.bank };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSaveBank"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.bank };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSaveBank"]);
        });
      }
    },
    update(data) {
      this.bank = new Bank();
      this.bank.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "bankCreate") {
          this.resetValidation(this.formName);
          this.bank = new Bank();
        }
        if (event.name == "bankUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
