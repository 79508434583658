export default class Bank {
    constructor() {
        this.id = "";
        this.number = "";
        this.name = "";
    }
    update(data) {
        this.id = data.id;
        this.number = data.number;
        this.name = data.name;
    }
}